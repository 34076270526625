<template>
    <nav>
        <div class="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="relative flex items-center justify-between h-16">
                <div
                    class="absolute inset-y-0 left-0 flex items-center sm:hidden"
                >
                    <!-- Mobile menu button-->
                    <button
                        class="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                        aria-label="Main menu"
                        aria-expanded="false"
                        @click="toggleMobileMenu"
                    >
                        <IconTimes
                            v-if="isMobileMenuOpen"
                            color="white"
                            class="h-5"
                        />
                        <IconBars v-else color="white" class="h-5" />
                    </button>
                </div>
                <div
                    class="flex items-center justify-center flex-1 hidden sm:block sm:items-stretch sm:justify-start"
                >
                    <div class="flex-shrink-0 pointer img-container">
                        <img
                            src="../../assets/images/logo-nextar-white-alpha.png"
                            alt="Nextar"
                            class="h-9 pointer"
                            @click="goToHome"
                        />
                    </div>

                    <div class="hidden sm:block sm:ml-0">
                        <div class="flex items-baseline ml-5 space-x-1"></div>
                    </div>
                </div>
                <div
                    class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                >
                    <!-- store dropdown -->
                    <div class="text-white text-right flex sm:hidden">
                        <small
                            class="text-cool-gray-500 leading-4"
                            @click="togglestoreMenu"
                        >
                            {{ getAccount }}
                            <br />
                            #{{ getShopCode }}
                        </small>
                    </div>
                    <div
                        class="text-white text-right hidden sm:flex"
                        data-cy="dashboard-box_account-nav"
                    >
                        <span class="leading-4">
                            {{ $t("components.navBar.greeting") }}
                            <b
                                class="ml-1 cursor-pointer h-6"
                                @click="togglestoreMenu"
                            >
                                {{ getOwner }}!
                            </b>
                            <br />
                            <small class="text-cool-gray-500 ml-1 h-6">
                                {{ getAccount }}
                                #{{ getShopCode }}
                            </small>
                        </span>
                    </div>
                    <div class="relative ml-3">
                        <div class="">
                            <button
                                id="user-menu"
                                class="flex text-sm transition duration-150 ease-in-out border-2 border-transparent rounded-full focus:outline-none"
                                aria-label="User menu"
                                aria-haspopup="true"
                                data-cy="navbar-cog-btn"
                                @click="togglestoreMenu"
                            >
                                <IconCog color="white" />
                            </button>
                        </div>
                        <transition name="store">
                            <div
                                v-show="isstoreMenuOpen"
                                v-click-outside="closeMenu"
                                class="absolute right-0 z-30 w-48 mt-2 origin-top-right rounded-md shadow-lg"
                            >
                                <div
                                    class="py-1 bg-white rounded-md shadow-xs"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu"
                                >
                                    <router-link
                                        :to="{ name: routeEditStore }"
                                        class="dd-menuitem"
                                        role="menuitem"
                                        data-cy="navbar-store-a"
                                    >
                                        {{ $t("components.navBar.profile") }}
                                    </router-link>
                                    <span
                                        class="block pt-3 mt-3 border-t border-gray-200"
                                    />
                                    <button
                                        class="dd-menuitem w-full"
                                        role="menuitem"
                                        data-cy="navbar-logout-a"
                                        @click="handleLogout"
                                    >
                                        {{ $t("components.navBar.loggout") }}
                                    </button>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>

        <transition name="mobile">
            <div v-show="isMobileMenuOpen" class="sm:hidden">
                <div class="px-2 pt-2 pb-3">
                    <router-link
                        :to="{ name: routeEditStore }"
                        class="item-mobile"
                    >
                        Home
                    </router-link>
                </div>
            </div>
        </transition>
    </nav>
</template>

<script>
import { AUTH_LOGOUT, LOGIN_MODULE } from "@/store/constants/login";
import { mapGetters, mapActions } from "vuex";
import { IconBars, IconTimes, IconCog } from "../Icons/index";
import { EDIT_STORE_VIEW, HOME_VIEW } from "@/router/constants";

export default {
    name: "Navbar",
    components: {
        IconBars,
        IconTimes,
        IconCog,
    },
    data() {
        return {
            routeHome: HOME_VIEW,
            routeEditStore: EDIT_STORE_VIEW,
            isMobileMenuOpen: false,
            isstoreMenuOpen: false,
        };
    },
    computed: {
        ...mapGetters("store", ["store"], { getLanguage: "language" }),

        getShopCode() {
            return this.store?.store?.shopCode;
        },
        getAccount() {
            return this.store?.store?.account;
        },
        getOwner() {
            return this.store?.person?.owner;
        },
    },
    watch: {
        $route() {
            this.isstoreMenuOpen = false;
            this.isMobileMenuOpen = false;
        },
    },
    methods: {
        ...mapActions({
            logout: `${LOGIN_MODULE}/${AUTH_LOGOUT}`,
        }),
        closeMenu() {
            if (this.isstoreMenuOpen) {
                this.isstoreMenuOpen = false;
            }
        },
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
        togglestoreMenu() {
            setTimeout(() => {
                this.isstoreMenuOpen = true;
            }, 100);
        },
        handleLogout() {
            this.logout();
        },
        goToHome() {
            this.$router.push({ name: HOME_VIEW });
        },
    },
};
</script>

<style lang="scss">
nav {
    @apply bg-black fixed z-30 w-full shadow;
}

.img-container {
    min-width: 74px !important;
}

.btn {
    @apply px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300;
    @apply transition duration-150 ease-in-out;
    &:focus {
        @apply outline-none text-white bg-gray-600;
    }
    &:hover {
        @apply text-white bg-gray-700;
    }
    &.router-link-exact-active,
    &.router-link-active {
        @apply text-white bg-gray-300;
    }
}

.dd-menuitem {
    @apply block px-4 py-2 text-sm leading-5 text-gray-700;
    @apply transition duration-150 ease-in-out;
    @apply flex items-center justify-between;
    &:hover {
        @apply bg-gray-300;
    }
    &:focus {
        @apply outline-none;
    }
}

.pointer {
    cursor: pointer;
}

.store-enter-active {
    @apply transition ease-out duration-100;
}
.store-enter {
    @apply transform opacity-0 scale-95;
}
.store-enter-to {
    @apply transform opacity-100 scale-100;
}
.store-leave-active {
    @apply transition ease-in duration-75;
}
.store-leave {
    @apply transform opacity-100 scale-100;
}
.store-leave-to {
    @apply transform opacity-0 scale-95;
}

.mobile-enter-active {
    @apply duration-200 ease-out;
}
.mobile-enter {
    @apply opacity-0 scale-95;
}
.mobile-enter-to {
    @apply opacity-100 scale-100;
}
.mobile-leave-active {
    @apply duration-100 ease-in;
}
.mobile-leave {
    @apply opacity-100 scale-100;
}
.mobile-leave-to {
    @apply opacity-0 scale-95;
}

.item-mobile {
    @apply mt-1 block duration-150 ease-in-out text-base transition;
}

.item-navbar {
    @apply text-sm;
}

.item-navbar,
.item-mobile {
    @apply font-medium px-3 py-2 rounded-md text-gray-300;
    &:hover {
        @apply bg-gray-600 text-white;
    }
    &:focus {
        @apply bg-gray-700 outline-none text-white;
    }
}
</style>
