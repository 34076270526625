<template>
  <footer class="ext-xs text-center text-gray-400 bg-transparent mt-0">
    <img class="w-24 m-auto" src="@/assets/images/logo-nextar-gray.svg" />
    <p v-html="$t('footer.message')" />
    <div class="leading-10 h-10">
      {{ $t("footer.securitySite") }}
      <img class="w-24 -mt-1 inline" src="@/assets/images/le-logo-wide2.svg" />
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped>
</style>
