var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex flex-col min-h-screen",
      attrs: { id: _vm.isDesktop ? "desktop-main" : "main" },
    },
    [
      !_vm.isDesktop
        ? _c("div", [_c("Navbar", { staticClass: "navbar" })], 1)
        : _vm._e(),
      _c(
        "main",
        { class: _vm.isDesktop ? "desktop-container" : "main container mt-16" },
        [
          _c(
            "div",
            { class: _vm.isDesktop ? "desktop-card" : "card card-checkout" },
            [
              _c(
                "div",
                { staticClass: "p-3" },
                [
                  _c("router-view"),
                  _vm.isDesktop && !_vm.isDashboard
                    ? _c(
                        "div",
                        {
                          staticClass: "absolute top-5 right-5 cursor-pointer",
                          on: { click: _vm.showAlertClose },
                        },
                        [_c("IconExit", { attrs: { color: "black" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      !_vm.isDesktop ? _c("div", [_c("Footer")], 1) : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }