var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    [
      _c("div", { staticClass: "px-2 mx-auto max-w-7xl sm:px-6 lg:px-8" }, [
        _c(
          "div",
          { staticClass: "relative flex items-center justify-between h-16" },
          [
            _c(
              "div",
              {
                staticClass:
                  "absolute inset-y-0 left-0 flex items-center sm:hidden",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white",
                    attrs: {
                      "aria-label": "Main menu",
                      "aria-expanded": "false",
                    },
                    on: { click: _vm.toggleMobileMenu },
                  },
                  [
                    _vm.isMobileMenuOpen
                      ? _c("IconTimes", {
                          staticClass: "h-5",
                          attrs: { color: "white" },
                        })
                      : _c("IconBars", {
                          staticClass: "h-5",
                          attrs: { color: "white" },
                        }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex items-center justify-center flex-1 hidden sm:block sm:items-stretch sm:justify-start",
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-shrink-0 pointer img-container" },
                  [
                    _c("img", {
                      staticClass: "h-9 pointer",
                      attrs: {
                        src: require("../../assets/images/logo-nextar-white-alpha.png"),
                        alt: "Nextar",
                      },
                      on: { click: _vm.goToHome },
                    }),
                  ]
                ),
                _vm._m(0),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0",
              },
              [
                _c(
                  "div",
                  { staticClass: "text-white text-right flex sm:hidden" },
                  [
                    _c(
                      "small",
                      {
                        staticClass: "text-cool-gray-500 leading-4",
                        on: { click: _vm.togglestoreMenu },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.getAccount) + " "),
                        _c("br"),
                        _vm._v(" #" + _vm._s(_vm.getShopCode) + " "),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "text-white text-right hidden sm:flex",
                    attrs: { "data-cy": "dashboard-box_account-nav" },
                  },
                  [
                    _c("span", { staticClass: "leading-4" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("components.navBar.greeting")) + " "
                      ),
                      _c(
                        "b",
                        {
                          staticClass: "ml-1 cursor-pointer h-6",
                          on: { click: _vm.togglestoreMenu },
                        },
                        [_vm._v(" " + _vm._s(_vm.getOwner) + "! ")]
                      ),
                      _c("br"),
                      _c(
                        "small",
                        { staticClass: "text-cool-gray-500 ml-1 h-6" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getAccount) +
                              " #" +
                              _vm._s(_vm.getShopCode) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "relative ml-3" },
                  [
                    _c("div", {}, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "flex text-sm transition duration-150 ease-in-out border-2 border-transparent rounded-full focus:outline-none",
                          attrs: {
                            id: "user-menu",
                            "aria-label": "User menu",
                            "aria-haspopup": "true",
                            "data-cy": "navbar-cog-btn",
                          },
                          on: { click: _vm.togglestoreMenu },
                        },
                        [_c("IconCog", { attrs: { color: "white" } })],
                        1
                      ),
                    ]),
                    _c("transition", { attrs: { name: "store" } }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isstoreMenuOpen,
                              expression: "isstoreMenuOpen",
                            },
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.closeMenu,
                              expression: "closeMenu",
                            },
                          ],
                          staticClass:
                            "absolute right-0 z-30 w-48 mt-2 origin-top-right rounded-md shadow-lg",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "py-1 bg-white rounded-md shadow-xs",
                              attrs: {
                                role: "menu",
                                "aria-orientation": "vertical",
                                "aria-labelledby": "user-menu",
                              },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dd-menuitem",
                                  attrs: {
                                    to: { name: _vm.routeEditStore },
                                    role: "menuitem",
                                    "data-cy": "navbar-store-a",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("components.navBar.profile")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("span", {
                                staticClass:
                                  "block pt-3 mt-3 border-t border-gray-200",
                              }),
                              _c(
                                "button",
                                {
                                  staticClass: "dd-menuitem w-full",
                                  attrs: {
                                    role: "menuitem",
                                    "data-cy": "navbar-logout-a",
                                  },
                                  on: { click: _vm.handleLogout },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("components.navBar.loggout")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c("transition", { attrs: { name: "mobile" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMobileMenuOpen,
                expression: "isMobileMenuOpen",
              },
            ],
            staticClass: "sm:hidden",
          },
          [
            _c(
              "div",
              { staticClass: "px-2 pt-2 pb-3" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "item-mobile",
                    attrs: { to: { name: _vm.routeEditStore } },
                  },
                  [_vm._v(" Home ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "hidden sm:block sm:ml-0" }, [
      _c("div", { staticClass: "flex items-baseline ml-5 space-x-1" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }