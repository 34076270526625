<template>
    <div :id="isDesktop ? 'desktop-main' :'main'"  class='flex flex-col min-h-screen' >
        <div v-if="!isDesktop">
            <Navbar class="navbar" />
        </div>
        <main :class="isDesktop ? 'desktop-container' : 'main container mt-16'">
            <div :class="isDesktop ? 'desktop-card' : 'card card-checkout'">
                <div class="p-3">
                    <router-view />
                    <div
                        v-if="isDesktop && !isDashboard"
                        class="absolute top-5 right-5 cursor-pointer"
                        @click="showAlertClose"
                    >
                        <IconExit color="black" />
                    </div>
                </div>
            </div>
        </main>
        <div v-if="!isDesktop">
            <Footer />
        </div>
    </div>
</template>

<script>
import Navbar from "../../components/Grid/Navbar.vue";
import Footer from "../../components/Grid/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import { GET_STORE, STORE_MODULE } from "../../store/constants/store";
import IconExit from "@/components/Icons/IconExit.vue";
import {  HOME_VIEW } from "@/router/constants";

export default {
    name: "Main",
    components: {
        Navbar,
        Footer,
        IconExit 
    },
    async created() {
              await this.requeststoreAction();
        },
    computed: {
        
        ...mapGetters({
            isDesktop: `isDesktop`
        }),
        isDashboard() {
            return this.$route.name ===  HOME_VIEW
        }
    },
    methods: {
        ...mapActions({
            requeststoreAction: `${STORE_MODULE}/${GET_STORE}`,
        }),
        showAlertClose() {
            return alert("@@fechar");
        },
    },
};
</script>
<style>
.desktop-container {
    width: 100vw;
}
.desktop-card {
    padding: 0.75rem;
    box-shadow: none;
}
#desktop-main {
    background-color: white;
}
</style>
